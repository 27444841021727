[v-cloak] {
  display: none
}

[v-cloak] > * {
  display: none
}

[v-cloak]::before {
  content: "loading…"
}

#page-container {
  overflow: hidden;
}

img:not([src]) {
  min-height: 100px;
}

.col-5ths {
  max-width: 20%;
}

body {
  min-width: 360px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.bg-white {
  .text-content {
    color: #212529;
  }
}

.bg-primary {
  .text-content {
    color: #f8f9fa;
  }

  .btn {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;

    &:hover {
      color: #212529;
      background-color: #e2e6ea;
      border-color: #dae0e5;
    }
  }

  .timeline {
    a {
      color: #f8f9fa;
      text-decoration: underline;

      &:hover {
        color: #b7b9ba;
      }
    }

    .btn {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      text-decoration: none;

      &:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
        text-decoration: underline;
      }
    }
  }
}

.bg-white {
  .text-content {
    color: $primary
  }

  .btn {
    color: #f8f9fa;
    background-color: $primary;
    border-color: #f8f9fa;
  }

  p {
    color: #212529
  }
}

.btn {
  font-size: 0.75rem;
  padding: 1em 1.5em;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.anim {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

// nav
#nav-logo {
  height: 15px;
  padding: 1px 0;
}

@media (min-width: 468px) {
  #nav-logo {
    height: 24px;
    padding: 2px 0;
  }
}

#head-logo {
  position: absolute;
  right: 0;
  margin-right: 90px;
  height: 130px;
}

#bottom-logo {
  position: relative;
  height: 130px;
  margin-top: -130px;
  margin-left: 60px;
}

// topmenu
.top-menu {
  .nav-link {
    &:hover {
      color: #6c757d;
    }
  }
}

// calendar
#calendar {
  min-height: 272px;
}

// events

#top-n {
  padding-bottom: 9rem !important;;
  .event-title {
    color: #f8f9fa !important;
  }
}

.event-title {
  color: $primary
}

.events {
  min-height: 45rem;

  .text-content {
    padding: 1.5rem;
  }
}

// footer

footer {
  padding-top: 75px;
  padding-bottom: 60px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: $primary;
}

a.badge-primary {
  color: #fff;
  background-color: $primary;

  &:hover {
    background-color: #c62d2b;
  }
}

.mark, mark {
  padding: .1em;
  background-color: rgba(0, 255, 0, 0.2);
}

footer .cafe img {
  height: 128px;
}

.alert-popup-wrapper {
  position: fixed;
  top: 64px;
  left: 25%;
  width: 50%;
  margin: 0 auto;
  z-index: 100000;

  .alert-popup {

  }
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}
