.gallery-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
  overflow: hidden;

  img {
    transform: scale(1);
    @include transition-all();
    &:hover {
      transform: scale(1.2);
    }
  }
  &:focus {
    outline: none;
  }
}
